import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const Image = ({
  className,
  description,
  height,
  image,
  style,
  title,
  width,
  wrap,
}) => (
  <figure>
    <Img
      alt={title}
      className={className}
      fluid={image}
      style={
        wrap
          ? { width: "33%" }
          : { ...style, height: `${height}px`, width: `${width}px` }
      }
    />
    {description && (
      <figcaption className="imagecaption">{description}</figcaption>
    )}
  </figure>
)

Image.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  height: PropTypes.number,
  image: PropTypes.shape({
    src: PropTypes.string,
  }),
  style: PropTypes.object,
  title: PropTypes.string,
  width: PropTypes.number,
  wrap: PropTypes.bool,
}

export default Image
