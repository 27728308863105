const generateFluidImage = (
  maxWidth,
  imageURL,
  quality = 90,
  imageWidth = 1280,
  imageHeight = 805
) => {
  const aspectRatio = imageWidth / imageHeight
  const sizes = `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`
  const src = `${imageURL}?w=${maxWidth}&q=${quality}`
  const widthRatiosArray = [0.25, 0.5]
  let widthRatio = 1

  while (maxWidth * widthRatio < imageWidth) {
    widthRatiosArray.push(widthRatio)
    widthRatio += 0.5
  }

  let srcSet = ""

  widthRatiosArray.forEach(r => {
    const width = r * maxWidth
    srcSet += `${imageURL}?w=${width}&h=${Math.ceil(
      width / aspectRatio
    )}&q=${quality} ${width}w,`
  })

  srcSet += `${imageURL}?w=${imageWidth}&h=${imageHeight}&q=${quality} ${imageWidth}w`

  return {
    aspectRatio,
    sizes,
    src,
    srcSet,
  }
}

export { generateFluidImage }
