import React from "react"
import PropTypes from "prop-types"
import Link from "components/elements/link"
import { CallToActionBaseProps } from "./props"
import Button from "components/sections/header/getInsuranceModel"
const RedirectAction = ({
  action = { url: "/" },
  buttonImage,
  buttonText,
  style,
  type,
}) => (
  <div>
    {type === "link" ? (
      <Link className={type} newTab={action.openInANewTab} to={action.url}>
        {buttonText}
      </Link>
    ) : (
      <Button
        buttonImage={buttonImage}
        buttonText={buttonText}
        style={style}
        type={type}
      />
    )}
  </div>
)

export const RedirectActionProps = {
  __typename: PropTypes.string,
  openInANewTab: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
}

RedirectAction.propTypes = {
  ...CallToActionBaseProps,
  action: PropTypes.shape(RedirectActionProps),
}

export default RedirectAction
