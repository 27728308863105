import React from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import scrollTo from "gatsby-plugin-smoothscroll"
import { CallToActionBaseProps } from "./props"
import Button from "components/elements/button"

const NavigateAction = ({ action, buttonImage, buttonText, style }) => (
  <div id="CTA">
    <Button
      className={style}
      handleClick={() => scrollTo(`#${slugify(action.title)}`)}
    >
      {buttonText}
      {buttonImage?.file?.url && (
        <img
          alt={buttonText}
          className="icon__img"
          src={buttonImage?.file?.url}
        />
      )}
    </Button>
  </div>
)

export const NavigateActionProps = {
  title: PropTypes.string,
}

NavigateAction.propTypes = {
  ...CallToActionBaseProps,
  action: PropTypes.shape(NavigateActionProps),
}

export default NavigateAction
