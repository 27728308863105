import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.css"
import "./model.css"

const Model = ({ buttonImage, buttonText, style }) => {
  const continueBtn = () => {
    navigate("/welcomevisit")
  }
  return (
    <>
      <div>
        <button className={style} onClick={continueBtn} type="button">
          <h5>{buttonText}</h5>
          {buttonImage?.file?.url && (
            <img
              alt={buttonText}
              className="icon__img"
              src={buttonImage?.file?.url}
            />
          )}
        </button>
      </div>
    </>
  )
}
Model.propTypes = {
  buttonImage: PropTypes.array.isRequired,
  buttonText: PropTypes.string.isRequired,
  style: PropTypes.array.isRequired,
}
export default Model
