import React from "react"
import PropTypes from "prop-types"
import "./button.scss"

/* Button */
/**
 * This is a button component used for CTA's and forms.
 */
const Button = ({
  btnType = "button",
  children,
  className = "primary-cta",
  disabled,
  handleClick,
  id,
}) => (
  <button
    className={className}
    disabled={disabled}
    id={id}
    onClick={handleClick}
    type={btnType}
  >
    {children}
  </button>
)

Button.propTypes = {
  btnType: PropTypes.string,
  /**
   Button label (required)
   */
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   Provides disabled state to button
   */
  disabled: PropTypes.bool,
  /**
   Callback function to execute additional code
   */
  handleClick: PropTypes.func,
  id: PropTypes.string,
}

export default Button
