import React from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import SECTIONS from "utils/sectionConstants"
import STYLES from "utils/stylesConstants"
import OpenVideoAction, { OpenVideoActionProps } from "./openVideoAction"
import RedirectAction, { RedirectActionProps } from "./redirectAction"
import NavigateAction, { NavigateActionProps } from "./navigateAction"
import FormAction, {
  FormActionProps,
  FormSubmissionActionProps,
} from "./formAction"
import { CallToActionBaseProps } from "./props"
import "./callToAction.scss"

const CallToAction = ({
  btnType,
  className,
  ctaWrapper,
  data,
  handleClick,
  type,
}) => {
  const linkStyle = "link"

  // Render CTA style classes
  const ButtonStyle = action => {
    if (action?.style) {
      switch (slugify(action?.style)) {
        case STYLES.Buttons.PlainText:
          return "plain-text-cta"

        case STYLES.Buttons.Purple:
          return "primary-cta"

        case STYLES.Buttons.White:
          return "white-cta"

        case STYLES.Buttons.Yellow:
          return "pastel-yellow-cta"

        case STYLES.Buttons.FormAction:
          return "form-action-cta"

        case STYLES.Buttons.WithBorder:
          return "with-Border-cta"

        default:
          return null
      }
    }
  }

  const renderCallToAction = action => {
    switch (action.action.__typename) {
      case SECTIONS.ActionRedirect:
        return (
          <RedirectAction
            {...action}
            style={`${
              type === "button" ? ButtonStyle(action) : linkStyle
            } ${className}`}
            type={type}
          />
        )

      case SECTIONS.ActionNavigate:
        return (
          <NavigateAction
            {...action}
            style={`${
              type === "button" ? ButtonStyle(action) : linkStyle
            } ${className}`}
          />
        )

      case SECTIONS.ActionFormSubmission:
        return null

      case SECTIONS.ActionForm:
        return (
          <FormAction
            {...action}
            btnType={btnType}
            style={`${
              type === "button" ? ButtonStyle(action) : linkStyle
            } ${className}`}
            type={type}
          />
        )

      case SECTIONS.ActionOpenVideo:
        return (
          <OpenVideoAction
            {...action}
            handleClick={handleClick}
            style={`${
              type === "button" ? ButtonStyle(action) : linkStyle
            } ${className}`}
            type={type}
          />
        )

      default:
        return (
          <RedirectAction
            {...action}
            style={`${
              type === "button" ? ButtonStyle(action) : linkStyle
            } ${className}`}
            type={type}
          />
        )
    }
  }

  return type === "link" ? (
    renderCallToAction(data)
  ) : (
    <div className={ctaWrapper}>{renderCallToAction(data)}</div>
  )
}

export const CallToActionProps = {
  ...CallToActionBaseProps,
  action: PropTypes.oneOfType([
    PropTypes.shape(FormActionProps),
    PropTypes.shape(FormSubmissionActionProps),
    PropTypes.shape(NavigateActionProps),
    PropTypes.shape(OpenVideoActionProps),
    PropTypes.shape(RedirectActionProps),
  ]),
}

CallToAction.propTypes = {
  btnType: PropTypes.string,
  className: PropTypes.string,
  ctaWrapper: PropTypes.string,
  data: PropTypes.shape(CallToActionProps),
  handleClick: PropTypes.func,
  type: PropTypes.string,
}

export default CallToAction
