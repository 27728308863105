import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"

const ExternalVideo = ({ title, url }) => {
  const [VimeoPlayer, setVimeoPlayer] = useState(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      import("@u-wave/react-vimeo")
        .then(module => {
          setVimeoPlayer(() => module.default)
        })
        .catch(error => console.error("Error loading Vimeo player:", error))
    }
  }, [])

  if (url.includes("vimeo")) {
    if (!VimeoPlayer) return <div>Loading video...</div>
    return <VimeoPlayer className="reactPlayer-video" video={url} />
  }

  return <ReactPlayer alt={title} className="reactPlayer-video" url={url} />
}

ExternalVideo.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export default ExternalVideo
