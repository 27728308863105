const styles = {
  Buttons: {
    FormAction: "Form-Action",
    PlainText: "Plain-Text",
    PlayButton: "Play-Button",
    Purple: "Purple",
    White: "White",
    WithBorder: "With-Border",
    Yellow: "Yellow",
  },
}

export default styles
