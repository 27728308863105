import PropTypes from "prop-types"

export const CallToActionBaseProps = {
  __typename: PropTypes.string,
  buttonImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  buttonText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.string,
  type: PropTypes.string,
}
