import React from "react"
import PropTypes from "prop-types"
import Button from "components/elements/button"
import CareersPageHeader from "components/sections/careersPageHeader"
import { CallToActionBaseProps } from "./props"

const FormAction = ({ action, btnType, buttonText, style, type }) => {
  const renderFormAction = () => {
    switch (action.formType) {
      case "Email Subscription":
        return (
          <Button btnType={btnType} className={style} type={type}>
            {buttonText}
          </Button>
        )

      case "Search Careers":
        return (
          <CareersPageHeader
            classNames="noAfter"
            searchOnly
            searchPlaceholder={action.label}
            style={{
              marginTop: "20px",
            }}
          />
        )

      default:
        return null
    }
  }

  return renderFormAction()
}

export const FormSubmissionActionProps = {
  action: PropTypes.string,
  title: PropTypes.string,
}

export const FormActionProps = {
  formType: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
}

FormAction.propTypes = {
  ...CallToActionBaseProps,
  action: PropTypes.shape(FormActionProps),
  btnType: PropTypes.string,
  buttonText: PropTypes.string,
}

export default FormAction
