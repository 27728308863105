import React from "react"
import PropTypes from "prop-types"
import Button from "components/elements/button"
import { CallToActionBaseProps } from "./props"

const OpenVideoAction = ({ buttonText, handleClick, style }) => (
  <Button className={style} handleClick={handleClick}>
    <span className="icon">
      <img
        alt="PlayIcon"
        className="play-icon"
        src="/images/play-button.svg"
        width="18"
      />
    </span>
    {buttonText}
  </Button>
)

export const OpenVideoActionProps = {
  openInANewTab: PropTypes.bool,
  title: PropTypes.string,
  videoWrapper: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
}

OpenVideoAction.propTypes = {
  ...CallToActionBaseProps,
  action: PropTypes.shape(OpenVideoActionProps),
  handleClick: PropTypes.func,
}

export default OpenVideoAction
