import React, { useState } from "react"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import SECTIONS from "utils/sectionConstants"
import HELPERS from "utils/helpers"
import { renderClasses } from "utils/renderClasses"
import { generateFluidImage } from "utils/image"
import Image from "components/elements/image"
import Link from "components/elements/link"
import CallToAction from "components/blocks/callToAction"
import QuoteSection from "components/sections/quoteSection"
import ExternalVideo from "components/elements/externalVideo"
import "./richText.scss"
import {
  caretDownDesktop,
  caretUpDesktop,
} from "components/sections/header/TopNav/Menu/Links/icons"

const RichText = ({
  alignment,
  backgroundImage,
  className,
  details,
  isCollapsible = false,
  richText,
  textStyle,
  title,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const toggleCollapse = () => setIsCollapsed(!isCollapsed)

  const renderEmbeddedEntry = node => {
    const contentful_id = node?.data?.target?.sys?.contentType?.sys?.id
    const contentful_data = node?.data?.target?.fields

    if (!contentful_id) return null

    switch (contentful_id) {
      case "callToAction": {
        const __typename = `Contentful${HELPERS.capitalizeFirstLetter(
          contentful_data?.action?.["en-US"]?.sys?.contentType?.sys?.id
        )}`

        switch (__typename) {
          case SECTIONS.ActionRedirect: {
            const redirectAction = {
              action: {
                __typename: __typename,
                openInANewTab:
                  contentful_data?.action?.["en-US"].fields?.openInANewTab?.[
                    "en-US"
                  ],
                url: contentful_data?.action?.["en-US"].fields?.url?.["en-US"],
              },
              buttonImage: {
                file: {
                  url: contentful_data?.buttonImage?.["en-US"]?.fields?.file?.[
                    "en-US"
                  ]?.url,
                },
              },
              buttonText: contentful_data?.buttonText?.["en-US"],
              style: contentful_data?.style?.["en-US"],
            }

            return <CallToAction data={redirectAction} type="button" />
          }

          case SECTIONS.ActionNavigate: {
            const navigateAction = {
              action: {
                __typename: __typename,
                title:
                  contentful_data?.action?.["en-US"].fields?.section?.["en-US"]
                    .fields?.title?.["en-US"],
              },
              buttonImage: {
                file: {
                  url: contentful_data?.buttonImage?.["en-US"]?.fields?.file?.[
                    "en-US"
                  ]?.url,
                },
              },
              buttonText: contentful_data?.buttonText?.["en-US"],
              style: contentful_data?.style?.["en-US"],
            }

            return <CallToAction data={navigateAction} type="button" />
          }

          default:
            return null
        }
      }

      case "sectionQuote":
        return (
          <QuoteSection
            name={
              contentful_data?.name?.["en-US"]
                ? contentful_data.name["en-US"]
                : ""
            }
            quote={{
              quote: contentful_data?.quote?.["en-US"],
            }}
          />
        )

      case "videoWrapper":
        return (
          <div className="center w-100">
            <ExternalVideo
              title={contentful_data?.title?.["en-US"]}
              url={contentful_data?.url?.["en-US"]}
            />
          </div>
        )

      case "photoWrapper": {
        const file = contentful_data?.photo?.["en-US"]?.fields?.file?.["en-US"]
        const title =
          contentful_data?.photo?.["en-US"]?.fields?.title?.["en-US"]
        const wrap = contentful_data?.wrap?.["en-US"]

        if (file?.contentType.startsWith("image")) {
          const url = file?.url
          const width = file?.details?.image?.width
          const height = file?.details?.image?.height

          return (
            <Image
              className={
                wrap && wrap !== "fill"
                  ? wrap === "right"
                    ? "float-right ml-3"
                    : "float-left mr-3"
                  : "my-4"
              }
              image={generateFluidImage(1000, url, 100, width, height)}
              title={title}
              wrap={wrap && wrap !== "fill"}
            />
          )
        } else {
          return null
        }
      }

      case "sectionRichText":
        return <RichText {...contentful_data} />

      default:
        return null
    }
  }

  const renderEmbeddedAsset = node => {
    const file = node?.data?.target?.fields?.file?.["en-US"]
    const title = node?.data?.target?.fields?.title?.["en-US"]
    const description = node?.data?.target?.fields?.description?.["en-US"]

    if (file?.contentType.startsWith("image")) {
      const url = file.url
      const width = file.details.image.width
      const height = file.details.image.height

      return (
        <Image
          className="my-4"
          description={description}
          image={generateFluidImage(1000, url, 100, width, height)}
          title={title}
        />
      )
    }
  }

  const defaultNodeRenderers = {
    [BLOCKS.DOCUMENT]: (_, children) => children,
    [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
    [BLOCKS.HEADING_1]: (_, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (_, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (_, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (_, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (_, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (_, children) => <h6>{children}</h6>,
    [BLOCKS.UL_LIST]: (_, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (_, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (_, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (_, children) => <blockquote>{children}</blockquote>,
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return renderEmbeddedAsset(node)
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      return renderEmbeddedEntry(node)
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <Link to={node?.data?.target?.fields?.file?.["en-US"]?.url}>
          {children}
        </Link>
      )
    },
    [INLINES.EMBEDDED_ENTRY]: node => {
      return renderEmbeddedEntry(node)
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return <Link to={node?.data?.uri}>{children}</Link>
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <Link to={node?.data?.uri}>{children}</Link>
    },
  }

  const defaultMarkRenderers = {
    [MARKS.BOLD]: content => <b>{content}</b>,
    [MARKS.ITALIC]: content => <i>{content}</i>,
    [MARKS.UNDERLINE]: content => <u>{content}</u>,
    [MARKS.CODE]: content => <code>{content}</code>,
  }

  const defaultTextRenderers = content => {
    return content.split("\n").reduce((children, textSegment, index) => {
      return [...children, textSegment === "", textSegment]
    }, [])
  }

  const options = {
    renderMark: defaultMarkRenderers,
    renderNode: defaultNodeRenderers,
    renderText: defaultTextRenderers,
  }

  const content = documentToReactComponents(
    richText
      ? richText.json
      : details?.["en-US"]
      ? details["en-US"]
      : details.json
      ? details.json
      : null,
    options
  )
  const styleBackground = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage.file.url})`,
        backgroundSize: "cover",
        borderRadius: "16px",
        padding: "0 16px",
      }
    : {}
  const isSectionText = className === "section-text"
  const colClasses =
    textStyle === "full"
      ? ""
      : className === "section-text" || className === "card-text"
      ? "col-md-10 col-12 p-0 m-0"
      : className === "headingText"
      ? "col-md-11 col-12 p-0 m-0"
      : "col-md-9 col-12 p-0 m-0"
  const justifyContent = isSectionText ? "" : "justify-content-center"

  return (
    <div className={renderClasses("container", [[className, className]])}>
      <div
        className={`row ${justifyContent} richText m-0`}
        style={{ ...styleBackground }}
      >
        <div className={`${colClasses} ${isSectionText ? "section-text" : ""}`}>
          <div
            className="content my-5"
            style={{
              textAlign: alignment?.["en-US"]
                ? alignment["en-US"]
                : alignment
                ? alignment
                : "left",
            }}
          >
            {isCollapsible && (
              <div className="toggle-collapse">
                <h1>{title}</h1>
                <div
                  aria-expanded={!isCollapsed}
                  className="toggle-caret"
                  onClick={toggleCollapse}
                >
                  {isCollapsed ? caretUpDesktop : caretDownDesktop}
                </div>
              </div>
            )}
            {!isCollapsed ? content : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export const RichTextProps = {
  alignment: PropTypes.string,
  backgroundImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  className: PropTypes.string,
  details: PropTypes.shape({
    "en-US": PropTypes.object,
    json: PropTypes.object,
  }),
  isCollapsible: PropTypes.bool,
  richText: PropTypes.shape({
    json: PropTypes.object,
  }),
  textStyle: PropTypes.string,
  title: PropTypes.string,
  web_page: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
}

RichText.propTypes = RichTextProps

export default RichText
