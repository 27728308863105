import React from "react"
import PropTypes from "prop-types"
import "./quoteSection.scss"

const QuoteSection = ({ name, quote }) => (
  <div className="container my-5 p-0">
    <div id="QuoteSection">
      {quote && <blockquote className="content">{quote?.quote}</blockquote>}
      {name && <p className="refernce m-0">{name}</p>}
    </div>
  </div>
)

export const QuoteSectionProps = {
  id: PropTypes.string,
  name: PropTypes.string,
  quote: PropTypes.shape({
    id: PropTypes.string,
    quote: PropTypes.string,
  }),
}

QuoteSection.propTypes = QuoteSectionProps

export default QuoteSection
